import React, {useEffect} from 'react';
export default function Career() {

  useEffect(() => {
    document.title = "EIT Ghana Limited - Career"
  }, []);

  return (
    <div className=' relative top-16'>Career</div>
  )
}
