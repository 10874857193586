import React, {useEffect} from 'react';
import EIT from '../assets/images/EITlogo.png';
import { motion } from 'framer-motion';

export default function About() {
  const containerVariants = {
    hidden : {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: {
        duration: 1,
      }
    },
    exit: {
      transition: {ease: 'easeInOut'}
    }
  }

  useEffect(() => {
    document.title = "EIT Ghana Limited - About Us"
  }, []);

  return (
    <motion.div 
      className="flex flex-col gap-16 bg-gray-200 relative top-16"
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      exit= "exit"
    >
      {/* About US */}
      <div className="h-[50vh] flex flex-col gap-10 place-content-center px-5 lg:px-20 py-10 lg:py-10 bg-blueblack">
        <div className="flex flex-col items-center justify-center gap-8">
          <div className="w-full lg:w-4/5">
            <h1 className="text-3xl font-bold text-center text-gray-300">About Us</h1>
            <p className="mt-4 text-center text-gray-100">
              EIT Ghana Limited is a technology company that provides innovative solutions to businesses and individuals. 
              We are a team of young and vibrant individuals who are passionate about technology and its application to solve problems.
            </p>
          </div>
        </div>
      </div>
      {/* Vision & Mission */}
      <motion.div 
        className="py-5 grid gap-10 lg:gap-0 px-5 lg:px-20 lg:flex"
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ delay: 1, duration: 1}}
        viewport={{ once: true }}
      >
        <div className="lg:w-1/2 grid place-items-center lg:bg-white rounded-md lg:shadow">
          <img src={EIT} alt="EIT Ghana Limited logo" className="lg:w-52 w-24" />
        </div>
        <div className="lg:w-1/2 flex flex-col gap-10 items-center container mx-auto lg:px-20">
          {/* Vision */}
          <div className="flex flex-col gap-5 container mx-auto bg-white p-4 rounded-md shadow">
            <h1 className="text-3xl font-bold text-center text-blueblack">Vision</h1>
            <p className="lg:text-justify">
              Our vision is to be the leading catalyst for entrepreneurial success in Ghana and beyond.
            </p>
          </div>
          {/* Mission */}
          <div className="flex flex-col gap-5 container mx-auto bg-white p-4 rounded-md shadow">
            <h1 className="text-3xl font-bold text-center text-blueblack">Mission</h1>
            {/* Old Mission Statement */}
            {/* <p className="lg:text-justify">
              Empowering entrepreneurs with the knowledge, skills, and resources to transform innovative ideas 
              into successful ventures, fueling economic growth and driving positive change.
            </p> */}

            {/* New Mission Statement */}
            <p className="lg:text-justify">
              Our mission is to empower aspiring entrepreneurs, startups and MSMEs with the knowledge, resources, 
              and support they need to succeed in today's dynamic business landscape.
            </p>
          </div>
        </div>
      </motion.div>
      {/* Our Team */}
      <motion.div 
        className="flex flex-col gap-10 container mx-auto px-5 lg:px-20 py-10 lg:py-10 bg-gray-100"
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ delay: 1, duration: 1}}
        viewport={{ once: true }}
      >
        <h1 className="text-3xl font-bold text-center text-blueblack">Our Team</h1>
        {/* Senior Members */}
        <div className="flex flex-col gap-5 container mx-auto">
          {/* <div className="">
            <h2 className="text-2xl font-bold text-blueblack">Senior Members</h2>
            <p className="text-blueblack">
              At EIT Ghana Limited, we are proud to have an esteemed group of individuals serving as Senior Members. These accomplished professionals, 
              consisting of lawyers, ministers, lecturers, and other distinguished experts, play vital roles in our company. They generously contribute 
              their knowledge, experience, and guidance, serving as coaches, investors, mentors, and patrons to help shape the success of our 
              organization and the entrepreneurs we support.
            </p>
          </div> */}
          {/* Senior Members Pictures */}
          {/* <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-5 lg:gap-10">
            <div className="group hover:bg-seablue overflow-hidden flex flex-col gap-5 items-center max-w-sm bg-gray-200 lg:min-w-42 border border-gray-200 rounded-lg shadow transition-all duration-500 ease-in-out transform">
              <img className="rounded-t-lg object-cover group-hover:opacity-80 group-hover:scale-110 transition-all duration-500 ease-in-out transform" src={require('../assets/images/avatar.png')} alt="" />
              <div className="p-3 lg:h-18">
                <h3 className="mb-2 group-hover:text-white text-xl font-bold text-blueblack transition-all duration-500 ease-in-out transform">Watchman Eric Ahiable</h3>
                <p className="mb-3 group-hover:text-white text-blueblack transition-all duration-500 ease-in-out transform">Mathemetician and senior lecturer at the University of Cape Coast . He is into AI...</p>
              </div>
            </div>
            <div className="group hover:bg-seablue overflow-hidden flex flex-col gap-5 items-center max-w-sm bg-gray-200 lg:min-w-42 border border-gray-200 rounded-lg shadow transition-all duration-500 ease-in-out transform">
              <img className="rounded-t-lg object-cover group-hover:opacity-80 group-hover:scale-110 transition-all duration-500 ease-in-out transform" src={require('../assets/images/avatar.png')} alt=""/>
              <div className="p-3 lg:h-18">
                <h3 className="mb-2 group-hover:text-white text-xl font-bold text-blueblack transition-all duration-500 ease-in-out transform">Dr. Stephen Moore</h3>
                <p className="mb-3 group-hover:text-white text-blueblack transition-all duration-500 ease-in-out transform">Dr. Moore is a Mathemetician and senior lecturer at the University of Cape Coast . He is into AI...</p>
              </div>
            </div>
            <div className="group hover:bg-seablue overflow-hidden flex flex-col gap-5 items-center max-w-sm bg-gray-200 lg:min-w-42 border border-gray-200 rounded-lg shadow transition-all duration-500 ease-in-out transform">
              <img  className="rounded-t-lg object-cover group-hover:opacity-80 group-hover:scale-110 transition-all duration-500 ease-in-out transform" src={require('../assets/images/avatar.png')} alt="" />
              <div className="p-3 lg:h-18">
                <h3 className="mb-2 group-hover:text-white text-xl font-bold text-blueblack transition-all duration-500 ease-in-out transform">Pastor Stephen Agyare</h3>
                <p className="mb-3 group-hover:text-white text-blueblack transition-all duration-500 ease-in-out transform">Mathemetician and senior lecturer at the University of Cape Coast . He is into AI...</p>
              </div>
            </div>
          </div> */}
        </div>
        {/* Junior Members */}
        <div className='mb-5 lg:mb-0'>
          <h2 className="mb-5 text-2xl font-bold text-blueblack">Members</h2>
          <div className="grid lg:grid-cols-4 md:grid-cols-3 gap-5 lg:gap-10 ">
            <div className="card h-fit group hover:bg-seablue overflow-hidden flex flex-col gap-5 items-center max-w-sm bg-gray-200 lg:min-w-42 border border-gray-200 rounded-lg shadow transition-all duration-500 ease-in-out transform">
              <img className="rounded-t-lg object-cover group-hover:opacity-80 group-hover:scale-110 transition-all duration-500 ease-in-out transform" src={require('../assets/images/ebenezer.jpeg')} alt="" />
              <div className="p-3 lg:h-18">
                <h3 className="mb-2 group-hover:text-white text-lg font-bold text-blueblack transition-all duration-500 ease-in-out transform">Ebenezer Dadzie</h3>
                <p className="mb-3 group-hover:text-white text-blueblack transition-all duration-500 ease-in-out transform">Managing Director</p>
              </div>
            </div>
            <div className="card h-fit group hover:bg-seablue overflow-hidden flex flex-col gap-5 items-center max-w-sm bg-gray-200 lg:min-w-42 border border-gray-200 rounded-lg shadow transition-all duration-500 ease-in-out transform">
              <img className="rounded-t-lg object-cover group-hover:opacity-80 group-hover:scale-110 transition-all duration-500 ease-in-out transform" src={require('../assets/images/rosemond.jpg')} alt="" />
              <div className="p-3 lg:h-18">
                <h3 className="mb-2 group-hover:text-white text-lg font-bold text-blueblack transition-all duration-500 ease-in-out transform">Rosemond Esi Abakah</h3>
                <p className="mb-3 group-hover:text-white text-blueblack transition-all duration-500 ease-in-out transform">Director of Training</p>
              </div>
            </div>
            <div className="group hover:bg-seablue overflow-hidden flex flex-col gap-5 items-center max-w-sm bg-gray-200 lg:min-w-42 border border-gray-200 rounded-lg shadow transition-all duration-500 ease-in-out transform">
              <img  className="rounded-t-lg object-cover group-hover:opacity-80 group-hover:scale-110 transition-all duration-500 ease-in-out transform" src={require('../assets/images/isaac.jpeg')} alt="" />
              <div className="p-3 lg:h-18">
                <h3 className="mb-2 group-hover:text-white text-lg font-bold text-blueblack transition-all duration-500 ease-in-out transform">Isaac Oboh Yamoah</h3>
                <p className="mb-3 group-hover:text-white text-blueblack transition-all duration-500 ease-in-out transform">Marketing Manager</p>
              </div>
            </div>
            <div className="group hover:bg-seablue overflow-hidden flex flex-col gap-5 items-center max-w-sm bg-gray-200 lg:min-w-42 border border-gray-200 rounded-lg shadow transition-all duration-500 ease-in-out transform">
              <img className="rounded-t-lg object-cover group-hover:opacity-80 group-hover:scale-110 transition-all duration-500 ease-in-out transform" src={require('../assets/images/avatar.png')} alt="" />
              <div className="p-3 lg:h-18">
                <h3 className="mb-2 group-hover:text-white text-lg font-bold text-blueblack transition-all duration-500 ease-in-out transform">Godwin Kingsley Woode</h3>
                <p className="mb-3 group-hover:text-white text-blueblack transition-all duration-500 ease-in-out transform">Software Engineer</p>
              </div>
            </div>
            <div className="group hover:bg-seablue overflow-hidden flex flex-col gap-5 items-center max-w-sm bg-gray-200 lg:min-w-42 border border-gray-200 rounded-lg shadow transition-all duration-500 ease-in-out transform">
              <img className="rounded-t-lg object-cover group-hover:opacity-80 group-hover:scale-110 transition-all duration-500 ease-in-out transform" src={require('../assets/images/samson.jpg')} alt="" />
              <div className="p-3 lg:h-18">
                <h3 className="mb-2 group-hover:text-white text-lg font-bold text-blueblack transition-all duration-500 ease-in-out transform">Samson Mayeem</h3>
                <p className="mb-3 group-hover:text-white text-blueblack transition-all duration-500 ease-in-out transform">Software Engineer</p>
              </div>
            </div>
            <div className="group hover:bg-seablue overflow-hidden flex flex-col gap-5 items-center max-w-sm bg-gray-200 lg:min-w-42 border border-gray-200 rounded-lg shadow transition-all duration-500 ease-in-out transform">
              <img  className="rounded-t-lg object-cover group-hover:opacity-80 group-hover:scale-110 transition-all duration-500 ease-in-out transform" src={require('../assets/images/avatar.png')} alt="" />
              <div className="p-3 lg:h-18">
                <h3 className="mb-2 group-hover:text-white text-lg font-bold text-blueblack transition-all duration-500 ease-in-out transform">Lawrence Ephrim</h3>
                <p className="mb-3 group-hover:text-white text-blueblack transition-all duration-500 ease-in-out transform">Software Engineer</p>
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    </motion.div>
  )
}