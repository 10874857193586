import React, {useRef, useEffect} from 'react';
import emailjs from '@emailjs/browser';
import { motion } from 'framer-motion';

export default function Contact() {
  const containerVariants = {
    hidden : {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: {
        duration: 1,
      }
    },
    exit: {
      transition: {ease: 'easeInOut'}
    }
  }

  const form = useRef();
  const sendEmail = (e) => { 
    e.preventDefault();
    emailjs.sendForm('service_ns9p1r8', 'template_bnndmof', form.current, '9_xhzH6d0qL8_WlYq')
      .then((result) => {
        console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
      e.target.reset();
    }

  useEffect(() => {
    document.title = "EIT Ghana Limited - Contact Us"
  }, []);

  return (
    <motion.div 
      className="flex flex-col relative top-16 bg-gray-200 w-full"
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      exit= "exit"
    >
      <div className="h-[50vh] flex flex-col place-content-center gap-10 px-5 lg:px-20 py-10 lg:py-10 bg-blueblack">
        <div className="flex flex-col items-center justify-center gap-8">
          <div className="w-full lg:w-4/5">
            <h1 className="text-3xl font-bold text-center text-gray-300">Contact Us</h1>
            <p className="mt-4 text-center text-gray-100">
              The only innovative hub you'll ever need
            </p>
          </div>
        </div>
      </div>

      {/* Contact */}
      <motion.div 
        className="grid gap-14 items-center px-5 lg:px-20 py-10 lg:py-10"
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ delay: 1, duration: 1}}
        viewport={{ once: true }}
      >
        <div className='grid gap-5'>
          <div className='text-center'>
            <h3 className="text-2xl font-bold text-blueblack">Phone Number</h3>
            <a href="tel:+233264175879" className='text-gray-600 font-medium'>+233 26 417 5879</a><br/>
            <a href="tel:+233544966829"  className='text-gray-600 font-medium'>+233 54 496 6829</a><br/>
            <a href="tel:+233247279067"  className='text-gray-600 font-medium'>+233 24 727 9067</a><br/>
          </div>
          <hr />
          <div className='text-center'>
            <h3 className="text-2xl font-bold text-blueblack">Email</h3>
            <a href="mailto:info@eitghana.com" className='text-gray-600 font-medium'>info@eitghana.com</a>
          </div>
        </div>
        {/* Contact Form */}
        <div className='lg:px-52'>
          <p className='text:lg md:text-xl text-gray-600 mb-10 font-medium'>
            We're delighted to hear from you! Please fill out the form below, 
            and we'll get back to you as soon as possible.
          </p>
          <form ref={form} onSubmit={sendEmail} className='grid gap-4'>
            <div className='grid'>
              <label className='font-medium text-gray-600' htmlFor="user_name">Name</label>
              <input className='bg-transparent rounded-lg p-3' type="text" placeholder='Name' name='user_name' id='user_name' required />
            </div>
            <div className='lg:flex grid gap-4'>
              <div className='grid lg:w-1/2'>
                <label className='font-medium text-gray-600' htmlFor="user_email">Email</label>
                <input className='bg-transparent rounded-lg p-3' type="text" placeholder='Email' name='user_email' id='user_email' required />
              </div>
              <div className='grid lg:w-1/2'>
                <label className='font-medium text-gray-600' htmlFor="tel">Phone Number</label>
                <input className='bg-transparent rounded-lg p-3' type='tel' placeholder='Phone Number' name='tel' id='tel' required />
              </div>
            </div>
            <div className='grid'>
              <label className='font-medium text-gray-600' htmlFor="subject">Subject</label>  
              <input className='bg-transparent rounded-lg p-3' type="text" placeholder='Subject' name='subject' id='subject' required />
            </div>
            <div className='grid'>
              <label className='font-medium text-gray-600' htmlFor="message">Message</label>
              <textarea className='bg-transparent rounded-lg p-3' name="message" cols="30" rows="10" placeholder='Message' id='message' required></textarea>
            </div>
            <button type='submit' className="flex justify-center items-center gap-10 bg-blueblack hover:bg-seablue text-white py-3 px-4 rounded-lg">
              Send
              <svg className="w-5 h-5 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 8 14">
                <path stroke="white" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 13 5.7-5.326a.909.909 0 0 0 0-1.348L1 1"/>
              </svg>
            </button>
          </form>
        </div>
      </motion.div>
    </motion.div>
  )
}
