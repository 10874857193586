import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { db } from '../../src/context/authFirebase';
import { collectionGroup, getDocs, where, query } from "firebase/firestore";
import { motion } from 'framer-motion';

export default function Careers() {
  // Read posts from database
  const [careers, setCareers] = useState([]);

  const containerVariants = {
    hidden : {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: {
        duration: 1,
      }
    },
    exit: {
      transition: {ease: 'easeInOut'}
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      const career = query(collectionGroup(db, "Post"), (where("category", "==", "careers")));

      try {
        const querySnapshot = await getDocs(career);
        const data = querySnapshot.docs.map(doc => doc.data());
        setCareers(data);
      }catch(err){
        console.log(err)
      }
      
    }
    fetchData();
  },[])

  useEffect(() => {
    document.title = "EIT Ghana Limited - Our Careers"
  }, []);


  return (
    <motion.div 
      className="flex flex-col bg-gray-200 relative top-16"
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      exit= "exit"
    >
    {/* careers and Events */}
      <div className="h-[50vh] flex flex-col gap-10 place-content-center px-5 lg:px-20 py-10 lg:py-10 bg-blueblack">
        <div className="flex flex-col items-center justify-center gap-8">
          <div className="w-full lg:w-4/5">
            <h1 className="text-3xl font-bold text-center text-gray-300">Careers</h1>
            <p className="mt-4 text-center text-gray-100">
              The only innovative hub you'll ever need
            </p>
          </div>
        </div>
      </div>
      <motion.div 
        className="flex flex-col container mx-auto"
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ delay: 1, duration: 1}}
        viewport={{ once: true }}
      >
        {/* Careers */}
        <div className="flex flex-col gap-10 container mx-auto px-5 lg:px-20 py-10 lg:py-10 bg-white">
          <h2 className="text-2xl font-bold text-blueblack">Open Roles</h2>
          {careers.length > 0 ? (
            <div className="grid md:grid-cols-2 md:gap-24 md:place-content-between lg:gap-12">
            {careers.map((post) => (
              <div key={post.id} className='lg:flex grid lg:gap-4'>
                <img className="w-full lg:w-64 md:object-cover h-64" src={post.file} alt="" />
                <div className="flex flex-col gap-5 items-center max-w-sm lg:min-w-42">
                  <div className="p-3 lg:h-auto">
                    <Link to={`/careers/${post.id}`} >
                      <h3 className="text-xl font-bold text-blueblack">{post.title}</h3>
                    </Link>
                    <p className="mb-3 text-blueblack line-clamp-3 break-all">{post.body}</p>
                    <Link to={`/careers/${post.id}`}>
                      <button className="px-4 py-2 bg-blueblack text-white text-sm font-semibold rounded-full hover:bg-seablue transition-all duration-500 ease-in-out transform">Read More</button>
                    </Link>
                  </div>
                </div>
              </div>
            ))}
            </div>
          ) : (
            <div className="text-center grid gap-10">
              <h3 className="text-2xl text-blueblack font-semibold">There are no current job openings or vacancies available.</h3>
              <p className="text-blueblack leading-loose">Don’t worry. Email your resume to<br /> <b>info@eitghana.com</b> <br />to be considered for future openings.</p>
            </div>
          )}
        </div>
      </motion.div>
    </motion.div>
    )
}
