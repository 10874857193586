import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Footer from '../components/Footer';
import UserNavbar from '../components/UserNavbar';
import { db } from '../context/authFirebase';
import { doc, getDoc, updateDoc, serverTimestamp } from 'firebase/firestore';
import { Link, useParams, useNavigate } from 'react-router-dom';

export default function EditPost() {
  const [title, setTitle] = useState('');
  const [body, setBody] = useState('');
  const { id } = useParams();
  const navigate = useNavigate();

  // Handle edit title
  const handleTitle = (e) => {
    setTitle(e.target.value);
  }

  // Handle edit body
  const handleBody = (e) => {
    setBody(e);
  }

  // Get post data to edit
  useEffect(() => {
    const getPost = async () => {
      const postRef = doc(db, 'Post', id);
      const postSnap = await getDoc(postRef);
      if (postSnap.exists()) {
        setTitle(postSnap.data().title);
        setBody(postSnap.data().body);
      } else {
        console.log('No such document!');
      }
    }
    getPost();
  }, [id]);

  // Handle edit post
  const handleEditPost = async (e) => {
    e.preventDefault();
    const currentDate = new Date().toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });

    try {
      const postRef = await doc(db, 'Post', id);
      await updateDoc(postRef, {
        title,
        body,
        timeStamp: serverTimestamp(),
        date: currentDate,
      });
      
      navigate('/dashboard');
    } catch (e) {
      console.error('Error adding document: ', e);
    }
  }

  return (
    <>
    <UserNavbar></UserNavbar>

    <div className="container mx-auto relative lg:top-24 top-20 px-5 lg:px-20 py-10 lg:py-10  font-sans">

      <Link to="/dashboard">
        <button className='mb-5 lg:mb-8 py-2 px-4 bg-blue-800 hover:bg-blue-600 rounded text-white font-semibold'>&#129048; Back to Dashboard</button>
      </Link>

      <div className='container grid gap-10 lg:flex lg:justify-between mx-auto lg:mb-10 text-blueblack'>
        {/* Title & Content */}
        <div className='lg:h-auto lg:w-2/3 grid gap-5'>
          {/* Title */}
          <div className='w-full'>
            <input 
              className='w-full'
              type="text"
              placeholder='Title'
              value={title}
              onChange={handleTitle}
              id='title'
            />
          </div>
          {/* Content */}
          <div className=''>
            <ReactQuill 
              className='md:h-40'
              theme="snow"
              // ref={editor}
              value={body}
              id='body'
              onChange={handleBody}
            />
          </div>

          <button 
            className='bg-blue-800 hover:bg-blue-600 text-white font-bold lg:mt-10 mt-2 py-2 px-4 rounded-sm disabled:bg-blue-300 disabled:cursor-not-allowed'
            onClick={handleEditPost}
          >Update
          </button>
        </div>
      </div>
    </div>

    <Footer></Footer>
    </>
  )
}
