import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Footer from '../components/Footer';
import UserNavbar from '../components/UserNavbar';
import { db, storage } from '../context/authFirebase';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { useNavigate, Link } from 'react-router-dom';

export default function AddPost() {
  const [title, setTitle] = useState('');
  const [body, setBody] = useState('');
  const [category, setCategory] = useState('');
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState('');
  const [successPost, setSuccessPost] = useState('');
  const [errorPost, setErrorPost] = useState('');
  const [perc, setPerc] = useState(null);
  const navigate = useNavigate();

  // Upload images
  const handleFile = (e) => {
    const selectedFile = e.target.files[0];
    if (!selectedFile) return;
    setFile(selectedFile);
    setFileName(selectedFile.name);

    const name = new Date().getTime() + selectedFile.name;
    const storageRef = ref(storage, name);
    const uploadTask = uploadBytesResumable(storageRef, selectedFile);

    // Listen for state changes, errors, and completion of the upload.
    uploadTask.on('state_changed', 
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setPerc(progress);
        console.log('Upload is ' + progress + '% done');
        switch (snapshot.state) {
          case 'paused':
            console.log('Upload is paused');
            break;
          case 'running':
            console.log('Upload is running');
            break;
          default:
            break;
        }
      }, 
      (error) => {
        // Handle unsuccessful uploads
      }, 
      () => {
        // Handle successful uploads on complete
        // For instance, get the download URL: https://firebasestorage.googleapis.com/...
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setFile(downloadURL);
        });
      }
    );
  }

  // Title
  const handleTitle = (e) => {
    setTitle(e.target.value);
  };

  // Handle body
  const handleBody = (e) => {
    setBody(e);
  };

  // Category
  const handleCategory = (e) => {
    setCategory(e.target.value);
  };

  // Write post to database using dabase
  const writePost = async (e) => {
    e.preventDefault();
    
    try{
      const currentDate = new Date().toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      });

      await addDoc(collection(db, "Post"), {
      title,
      body: body,
      file,
      category,
      timeStamp: serverTimestamp(),
      date: currentDate,
    });
      setSuccessPost('Post submitted successfully.');
      setErrorPost('');
      setTitle('');
      setBody('');
      setCategory('');
      setFileName('');
      setFile(null);

      navigate('/dashboard');
    }catch(err){
      console.log(err);
      setErrorPost('An error occurred while submitting the post.');
      setSuccessPost('');
    }
  };

  return (
    <>
    <UserNavbar></UserNavbar>
    <div className="container mx-auto relative lg:top-24 top-20 px-5 lg:px-20 py-10 lg:py-10  font-sans">

      <Link to="/dashboard">
        <button className='mb-5 lg:mb-8 py-2 px-4 bg-blue-800 hover:bg-blue-600 rounded text-white font-semibold'>&#129048; Back to Dashboard</button>
      </Link>

      <div className='container grid gap-10 lg:flex lg:justify-between mx-auto lg:mb-10 text-blueblack'>
        {/* Title & Content */}
        <div className='lg:h-auto lg:w-2/3 grid gap-5'>
          {/* Title */}
          <div className='w-full'>
            <input 
              className='w-full'
              type="text"
              placeholder='Title'
              value={title}
              onChange={handleTitle}
              id='title'
            />
          </div>
          {/* Content */}
          <div className=''>
            <ReactQuill 
              className='md:h-40'
              theme="snow"
              // ref={editor}
              value={body}
              id='body'
              onChange={handleBody}
            />
          </div>
        </div>

        {/*  */}
        <div className='grid gap-10 lg:w-1/3 lg:h-40'>
          {/* Category */}
          <div className='grid gap-2 border p-2'>
            <h3 className='font-bold text-xl'>Category</h3>
            <div className='grid justify-between'>
              <div className='flex gap-3 items-center'>
                <input 
                  type="radio"
                  name="category"
                  value="careers"
                  id="careers"
                  onChange={handleCategory}
                />
                <label htmlFor='careers'>Careers</label>
              </div>
              <div className='flex gap-3 items-center'>
                <input
                  type="radio"
                  name="category"
                  value="news"
                  id="news"
                  onChange={handleCategory}
                  />
                <label htmlFor='news'>News</label>
              </div>
              <div className='flex gap-3 items-center'>
                <input 
                  type="radio"
                  name="category"
                  value="events"
                  id="events"
                  onChange={handleCategory}
                />
                <label htmlFor='events'>Events</label>
              </div>
            </div>
          </div>

          {/* Image Upload */}
          <div className='grid gap-2 border p-2 max-h-auto'>
            <div className='flex justify-between'>
              {/* <button 
                className='bg-green-800 hover:bg-green-600 text-white font-bold py-2 px-4 rounded-sm'
              >Update</button> */}
              <div>
                <input 
                  className='hidden'
                  type="file"
                  id="file"
                  placeholder='Image'
                  accept='image/*'
                  onChange={handleFile}
                  // name='file'
                />
                <label className='cursor-pointer' htmlFor="file">Upload Image</label>
                {fileName && <><div className='italic text-xs'>{fileName}</div></>}
                {/* {perc !== null && <><div>{perc}%</div></>} */}
              </div>
              <button 
                className='bg-blue-800 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-sm disabled:bg-blue-300 disabled:cursor-not-allowed'
                onClick={writePost}
                disabled={perc !== null && perc < 100}
              >Submit</button>
            </div>
            {errorPost && <><div className='text-red-600'>{errorPost}</div></> }
            {successPost && <><div>{successPost}</div></> }
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
    </>
  )
}
