import React, { useState } from 'react';
import EIT from '../assets/images/EITlogo.jpg';
import { useNavigate } from 'react-router-dom';
import { auth } from '../../src/context/authFirebase';
import { signInWithEmailAndPassword } from 'firebase/auth';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const login = async (e) => {
    e.preventDefault();
    signInWithEmailAndPassword(auth, email, password)
    .then((userAuth) => {
      console.log(userAuth)
      navigate('/dashboard')
    }).catch((err) => alert(err.message))
  }

  return (
    <div className='h-screen grid place-items-center bg-seablue'>
      <div className='grid place-items-center gap-5'>
        <img src={EIT} alt="EIT logo" className='lg:w-52 w-24 rounded-full'/>
        <h1 className='text-3xl font-bold text-white'>Login</h1>
        <form className='grid gap-2' action='' onSubmit={login}>
          <input 
            className='w-72 p-2 rounded-lg'
            type="email"
            name="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required 
          />
          <input 
            className='w-72 p-2 rounded-lg'
            type="password"
            name="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required 
          />
          <button type="submit" 
            // onClick={handleSubmit}
            className='font-medium bg-blueblack text-white p-2 rounded-full hover:bg-blue-800'>
              Login
          </button>
          {/* {err && <p className='font-medium text-white text-center'>{err}</p>} */}
        </form>
      </div>
    </div>
  )
}

export default Login;
