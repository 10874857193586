import React, {useEffect} from 'react';
import heroimage from '../assets/images/heroimage.png';
import entrepreneurship from '../assets/images/entrepreneurship.jpg';
import innovation from '../assets/images/innovation.jpg';
import technology from '../assets/images/technology.jpg';
import marketing from '../assets/images/marketing.jpg';
import { HashLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { TypeAnimation } from 'react-type-animation';
import { Card } from 'flowbite-react';
import Client1 from '../assets/images/centralRoofing.png';
import Client2 from '../assets/images/gml.png';
import Client3 from '../assets/images/BuahAssociates-preview.png'

export default function Home() {
  const containerVariants = {
    hidden : {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: {
        duration: 1,
      }
    },
    exit: {
      transition: {ease: 'easeInOut'}
    }
  }

  const logos = [Client1, Client2, Client3];

  useEffect(() => {
    document.title = "EIT Ghana Limited";
  }, []);

  return (
    <motion.div 
      className='relative top-8 lg:top-0'
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      exit= "exit"
    >
      {/* First Part */}
      <div className="grid content-center h-screen bg-gray-100 dark:bg-gray-900">
        <motion.div 
          className="grid lg:flex gap-16 md:gap-40 justify-items-center lg:justify-between mx-5 lg:mx-20 lg:pt-16"
          initial={{ opacity: 0}}
          animate={{ opacity: 1}}
          transition={{ delay: 0.2, duration: 1.5}}
        >
          <div className="mx-auto lg:w-2/3">
            <div className="">
              <h1 className="text-xl text-blueblack md:text-2xl lg:text-4xl text-center lg:text-left">Welcome to</h1>
              <h1 className="text-xl font-bold text-blueblack  md:text-2xl lg:text-4xl text-center lg:text-left">EIT Ghana Limited</h1>
              <p className="mt-2 text-seablue md:font-medium text-sm md:text-xl lg:text-left">Entrepreneurship, Innovation and Technology.</p>
              <div className="flex mt-6 justify-center lg:justify-start">
                <Link href="/about" className="py-2 px-3 font-medium bg-blueblack text-white rounded-full transition-all duration-500 ease-in-out transform">Learn More</Link>
                <Link href="/contact" className="mx-4 py-2 px-3 font-medium bg-seablue text-white rounded-full transition-all duration-500 ease-in-out transform">Contact Us</Link>
              </div>
            </div>
          </div>
          <img src={heroimage} alt="EIT Hero" className="object-cover lg:w-1/3" />
        </motion.div>
        <div className='mx-5 lg:mx-20 tracking-widest text-seablue md:font-medium text-xl md:text-2xl lg:text-4xl text-center lg:text-left pt-12 md:pt-32 lg:pt-8'>
          <TypeAnimation
            sequence={[
              'Entrepreneurship',
              1000,
              'Innovation',
              1000,
              'Technology',
              1000,
            ]}
            wrapper="span"
            speed={50}
            cursor={true}
            repeat={Infinity}
          />
        </div>
      </div>

      {/* Second Part */}
      <motion.div 
        className='px-5 lg:px-20 pt-10 lg:pt-10 bg-gray-100 grid place-items-center'
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ delay: 1, duration: 1}}
        viewport={{ once: true }}
      >
        <div className='w-full bg-seablue -mt-24 rounded-xl mb-8'>
          <motion.h1 
            className="text-3xl font-bold text-center text-white py-5"
            initial={{ y: 50, opacity: 0}}
            whileInView={{ y:0, opacity: 1}}
            transition={{
              delay: 0.4,
              y: {type: 'spring', stiffness: 60},
              opacity: { duration: 0.2},
              duration: 1
            }}
          >
            What We Do
          </motion.h1>
          <div className='grid lg:grid-cols-4 md:grid-cols-2 gap-3 lg:gap-5 m-3 lg:m-5 text-center md:text-lg font-bold text-seablue'>
            <Card className="bg-white border border-gray-200 rounded-lg shadow hover:bg-blueblack hover:text-white ease-in-out transition delay-150 duration-300 hover:scale-105">
              <h2 className="-mx-5">Web Development</h2>
            </Card>
            <Card className="bg-white border border-gray-200 rounded-lg shadow hover:bg-blueblack hover:text-white ease-in-out transition delay-150 duration-300 hover:scale-105">
              <h2 className="-mx-5">Mobile Development</h2>
            </Card>
            <Card className="bg-white border border-gray-200 rounded-lg shadow hover:bg-blueblack hover:text-white ease-in-out transition delay-150 duration-300 hover:scale-105">
              <h2 className="-mx-5">Data Analysis</h2>
            </Card>
            <Card className="bg-white border border-gray-200 rounded-lg shadow hover:bg-blueblack hover:text-white ease-in-out transition delay-150 duration-300 hover:scale-105">
              <h2 className="-mx-5">Digital Marketing</h2>
            </Card>
            <Card className="bg-white border border-gray-200 rounded-lg shadow hover:bg-blueblack hover:text-white ease-in-out transition delay-150 duration-300 hover:scale-105">
              <h2 className="-mx-5">Graphic Design</h2>
            </Card>
            <Card className="bg-white border border-gray-200 rounded-lg shadow hover:bg-blueblack hover:text-white ease-in-out transition delay-150 duration-300 hover:scale-105">
              <h2 className="-mx-5">Entrepreneurship Training</h2>
            </Card>
            <Card className="bg-white border border-gray-200 rounded-lg shadow hover:bg-blueblack hover:text-white ease-in-out transition delay-150 duration-300 hover:scale-105">
              <h2 className="-mx-5">Vocational Training</h2>
            </Card>
            <Card className="bg-white border border-gray-200 rounded-lg shadow hover:bg-blueblack hover:text-white ease-in-out transition delay-150 duration-300 hover:scale-105">
              <h2 className="-mx-5">Venture Capital</h2>
            </Card>
          </div>
        </div>
      </motion.div>

      {/* Third Part */}
      <motion.div 
        className="flex flex-col gap-10 container mx-auto px-5 lg:px-20 py-10 lg:py-10 bg-gray-200"
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ delay: 1, duration: 1}}
        viewport={{ once: true }}
      >
        <motion.h1 
          className="text-3xl font-bold text-center text-seablue"
          initial={{ y: 50, opacity: 0}}
          whileInView={{ y:0, opacity: 1}}
          transition={{
            delay: 0.4,
            y: {type: 'spring', stiffness: 60},
            opacity: { duration: 0.2},
            duration: 1
          }}
        >
          What We Offer
        </motion.h1>
        {/*  */}
        <div className="flex flex-col gap-5 container mx-auto">
          {/* <div className="">
            <h2 className="text-2xl font-bold text-blueblack">Senior Members</h2>
            <p className="text-blueblack">
              At EIT Ghana Limited, we are proud to have an esteemed group of individuals serving as Senior Members. These accomplished professionals, 
              consisting of lawyers, ministers, lecturers, and other distinguished experts, play vital roles in our company. They generously contribute 
              their knowledge, experience, and guidance, serving as coaches, investors, mentors, and patrons to help shape the success of our 
              organization and the entrepreneurs we support.
            </p>
          </div> */}
          {/* What we offer */}
          <div className="grid lg:grid-cols-4 md:grid-cols-2 gap-5 place-items-center lg:content-center">
            <div className="flex flex-col gap-2 items-center max-w-sm bg-white w-80 lg:w-64 h-80 border border-gray-200 rounded-lg shadow">
              <img className="rounded-3xl object-cover w-full h-3/5" src={technology} alt='technology' />
              <div className="px-3 h-2/5 py-2 lg:pt-0 grid content-between items-start justify-items-center">
                <HashLink smooth to="/services#its" className="mb-2 text-center leading-5 text-xl font-semibold text-seablue">Commercial IT Services</HashLink>
                <HashLink smooth to="/services#its" className="px-4 py-2 bg-seablue text-white text-sm font-medium rounded-full hover:bg-blueblack transition-all duration-500 ease-in-out transform">Learn More</HashLink>
              </div>
            </div>
            <div className="flex flex-col gap-2 items-center max-w-sm bg-white w-80 lg:w-64 h-80 border border-gray-200 rounded-lg shadow">
              <img className="rounded-3xl object-cover w-full h-3/5" src={entrepreneurship} alt="entrepreneurship" />
              <div className="px-3 h-2/5 py-2 lg:pt-0 grid content-between items-start justify-items-center">
                <HashLink smooth to="/services#vets" className="mb-2 text-center leading-5 text-xl font-semibold text-seablue">Vocational & Entrepreneurship Training</HashLink>
                <HashLink smooth to="/services#vets" className="px-4 py-2 bg-seablue text-white text-sm font-medium rounded-full hover:bg-blueblack transition-all duration-500 ease-in-out transform">Learn More</HashLink>
              </div>
            </div>
            <div className="flex flex-col gap-2 items-center max-w-sm bg-white w-80 lg:w-64 h-80 border border-gray-200 rounded-lg shadow">
              <img className="rounded-3xl object-cover w-full h-3/5" src={innovation} alt='innovation' />
              <div className="px-3 h-2/5 py-2 lg:pt-0 grid content-between items-start justify-items-center">
                <HashLink smooth to="/services#vcs" className="mb-2 text-center leading-5 text-xl font-semibold text-seablue">Venture Capital Services</HashLink>
                <HashLink smooth to="/services#vcs" className="px-4 py-2 bg-seablue text-white text-sm font-medium rounded-full hover:bg-blueblack transition-all duration-500 ease-in-out transform">Learn More</HashLink>
              </div>
            </div>
            <div className="flex flex-col gap-2 items-center max-w-sm bg-white w-80 lg:w-64 h-80 border border-gray-200 rounded-lg shadow">
              <img className="rounded-3xl object-cover w-full h-3/5" src={marketing} alt='marketing' />
              <div className="px-3 h-2/5 py-2 lg:pt-0 grid content-between items-start justify-items-center">
                <HashLink smooth to="/services#mkts" className="mb-2 text-center leading-5 text-xl font-semibold text-seablue">Marketing Services</HashLink>
                <HashLink smooth to="/services#mkts" className="px-4 py-2 bg-seablue text-white text-sm font-medium rounded-full hover:bg-blueblack transition-all duration-500 ease-in-out transform">Learn More</HashLink>
              </div>
            </div>
          </div>
        </div>
      </motion.div>

      {/* Fourth Part */}
      <motion.div 
        className='px-5 lg:px-20 py-10 lg:py-10 bg-gray-100'
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ delay: 1, duration: 1}}
        viewport={{ once: true }}
      >
        <div className='bg-seablue lg:h-[40vh] py-10 px-2 lg:p-5 grid content-center'>
          <div className="grid grid-cols-3 divide-x text-white text-center">
            {/* <div className='grid content-between'>
              <h1 className='text-3xl md:text-5xl lg:text-7xl font-bold pb-5'>30+</h1>
              <p>Projects Delivered</p>
            </div>
            <div className='grid content-between'>
              <h1 className='text-3xl md:text-5xl lg:text-7xl font-bold pb-5'>50+</h1>
              <p>Clients and Partners</p>
            </div>
            <div className='grid content-between'>
              <h1 className='text-3xl md:text-5xl lg:text-7xl font-bold pb-5'>50+</h1>
              <p className=''>Entrepreneurs trained</p>
            </div> */}
          </div>
        </div>
      </motion.div>

      {/* Last Part */}
      <motion.div 
        className='px-5 lg:px-20 py-10 lg:py-10 lg:-mb-16 bg-gray-200'
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ delay: 1, duration: 1}}
        viewport={{ once: true }}
      >
        <motion.h1 
          className="text-3xl font-bold text-center text-seablue"
          initial={{ y: 50, opacity: 0}}
          whileInView={{ y:0, opacity: 1}}
          transition={{
            delay: 0.4,
            y: {type: 'spring', stiffness: 60},
            opacity: { duration: 0.2},
            duration: 1
          }}
        >
          Our Clients and Partners
        </motion.h1>
        <div className="flex justify-center content-center space-x-4 overflow-hidden scrollbar-hide pt-10" >
          <div className="flex flex-wrap justify-center items-center space-x-4">
            {logos.map((logo, index) => (
              <img key={index} src={logo} alt={`Client Logo ${index + 1}`} className="h-4 md:h-8 lg:h-12 xl:h-16 mb-4" />
            ))}
          </div>
        </div>
      </motion.div>
    </motion.div>
  )
}
