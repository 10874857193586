import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { db } from '../context/authFirebase';
import { doc, getDoc, getDocs, collection, query, orderBy, where, limit } from 'firebase/firestore';

export default function News() {
  const { id } = useParams();
  const [news, setNews] = useState(null);
  const [otherNews, setOtherNews] = useState([]);

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const postRef = doc(db, 'Post', id); // Reference the 'posts' collection and the specific post by ID
        const docSnap = await getDoc(postRef);

        if (docSnap.exists()) {
          setNews(docSnap.data());
        } else {
          console.log('Post not found');
        }

        // Get other news
        const otherNewsQuery = query(
          collection(db, 'Post'),
          where('category', '==', 'news'),
          orderBy('timeStamp', 'desc'),
          limit(3)
        );
        const otherNewsSnapshot = await getDocs(otherNewsQuery);
        const otherNewsData = otherNewsSnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));

        // Filter out the current news
        const filteredNews = otherNewsData.filter((post) => post.id !== id);
        setOtherNews(filteredNews);

      } catch (error) {
        console.error('Error fetching post:', error);
      }
    };

    fetchNews();
  }, [id]);

  useEffect(() => {
    document.title = "EIT Ghana Limited - News"
  }, []);

  if(!news) return <h1 className='text-3xl text-center font-bold text-blueblack mx-auto px-5 lg:px-20 py-10 lg:py-10'>News post not found.</h1>;

  return (
    <div className='lg:flex relative top-16'>
      {/* Main News */}
      {news && (
        <div className='lg:w-3/4 flex flex-col gap-5 container mx-auto px-5 lg:pl-20 py-10 lg:py-10'>
          <div>
            <img src={news.file} className='w-full lg:h-72 object-cover mb-12' alt={news.title} />
            <div>
              <h3 className='text-3xl font-bold text-blueblack'>{news.title}</h3>
              {/* <p className='mt-4 text-blueblack'>{news.body}</p> */}
              <div className='mt-4 text-blueblack' dangerouslySetInnerHTML={{ __html: news.body }} />
            </div>
          </div>
        </div>
      )}

      {/* Other News */}
      <div className='lg:w-1/4 flex flex-col gap-5 container mx-auto px-5 lg:pr-20 py-10 lg:py-10'>
        <h3 className='text-xl font-bold text-blueblack'>Other news</h3>
        {otherNews.map((post) => (
          <div key={post.id}>
            <img src={post.file} className='w-full hidden lg:block' alt={post.title} />
            <div className='px-2'>
              <Link to={`/news/${post.id}`} > 
                <h3 className="text-xl font-bold text-blueblack">{post.title}</h3>
              </Link>
              {/* <p className='mb-2 text-blueblack line-clamp-3 break-all'>{post.body}</p> */}
              <div dangerouslySetInnerHTML={{ __html: post.body }} className='mb-2 text-blueblack line-clamp-3 break-all' />
              <Link to={`/news/${post.id}`} >
                <button className="px-4 py-2 bg-blueblack text-white text-sm font-semibold rounded-full hover:bg-seablue transition-all duration-500 ease-in-out transform"
                // onClick={() => handleSelectData(post)}
                >
                  Read More
                </button>
              </Link>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}