import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  // apiKey: process.env.REACT_APP_FIREBASE_KEY,
  apiKey: "AIzaSyCcb2-UU2VeEGonCeKtge7Z9mPbRE9oKug",
  authDomain: "eitghana-ltd.firebaseapp.com",
  projectId: "eitghana-ltd",
  storageBucket: "eitghana-ltd.appspot.com",
  messagingSenderId: "785890736297",
  appId: "1:785890736297:web:805a4c19da14316803d1b8",
  measurementId: "G-BBD9GRPF7V",
  databaseURL: "https://eitghana-ltd-default-rtdb.firebaseio.com/",
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);

export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);