import React, { useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import { db } from '../../src/context/authFirebase';
import { collectionGroup, getDocs, query, where, orderBy } from "firebase/firestore";
import { motion } from 'framer-motion';


export default function NewsEvents() {
  // Read posts from database
  const [news, setNews] = useState([]);
  const [events, setEvents] = useState([]);

  const containerVariants = {
    hidden : {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: {
        duration: 1,
      }
    },
    exit: {
      transition: {ease: 'easeInOut'}
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      const news = query(
        collectionGroup(db, "Post"), 
        where("category", "==", "news"), 
        orderBy("timeStamp", "desc")
      );
      const events = query(
        collectionGroup(db, "Post"), 
        where("category", "==", "events"), 
        orderBy("timeStamp", "desc")
      );

      try {
        const getNews = async () => {
          const newsData = await getDocs(news);
          setNews(newsData.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        };
        getNews();

        const getEvents = async () => {
          const eventsData = await getDocs(events);
          setEvents(eventsData.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        };
        getEvents();

      }catch(err){
        console.log(err);
      } 
    }
    fetchData();
  },[])

  useEffect(() => {
    document.title = "EIT Ghana Limited - News & Events"
  }, []);

  return (
    <motion.div 
      className="flex flex-col bg-gray-200 relative top-16"
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      exit= "exit"
    >
      {/* News and Events */}
      <div className="h-[50vh] flex flex-col gap-10 place-content-center px-5 lg:px-20 py-10 lg:py-10 bg-blueblack">
        <div className="flex flex-col items-center justify-center gap-8">
          <div className="w-full lg:w-4/5">
            <h1 className="text-3xl font-bold text-center text-gray-300">News & Events</h1>
            <p className="mt-4 text-center text-gray-200">
              EIT Ghana Limited is a technology company that provides innovative solutions to businesses and individuals. 
              We are a team of young and vibrant individuals who are passionate about technology and its application to solve problems.
            </p>
          </div>
        </div>
      </div>

      <motion.div 
        className="flex flex-col container mx-auto"
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ delay: 1, duration: 1}}
        viewport={{ once: true }}
      >
        {/* News*/}
        <div className="flex flex-col gap-5 container mx-auto px-5 lg:px-20 py-10 lg:py-10 bg-white">
          <h2 className="text-2xl font-bold text-blueblack">News</h2>
          {news.length > 0 ? (
            <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-5 lg:gap-10">
            {news.map(post => (
            <div key={post.id} className="flex flex-col gap-5 items-center max-w-sm bg-gray-200 lg:min-w-42 border border-gray-200 rounded-lg shadow">
              <img className="w-full rounded-t-lg object-cover h-52" src={post.file} alt={post.title} />
              <div className="p-3 lg:h-18 content-between">
                <Link to={`/news/${post.id}`} > 
                  <h3 className="mb-2 text-l font-bold text-blueblack">{post.title}</h3>
                </Link>
                {/* <p className="mb-3 text-blueblack line-clamp-3 break-all">{post.body}</p> */}
                <div dangerouslySetInnerHTML={{ __html: post.body }} className="mb-3 text-blueblack line-clamp-3 break-all" />
                <div className='flex justify-between items-center'>
                  <Link to={`/news/${post.id}`} >
                    <button className="px-4 py-2 bg-blueblack text-white text-sm font-semibold rounded-full hover:bg-seablue transition-all duration-500 ease-in-out transform"
                    // onClick={() => handleSelectedNews(post)}
                    >
                      Read More</button>
                  </Link>
                  <p className="text-sm text-blueblack font-semibold">{post.date}</p>
                </div>
              </div>
            </div>
            ))}
          </div>
          ) : (
            <div className="text-center grid gap-10">
              <h3 className="text-2xl text-blueblack font-semibold">There are no current news available.</h3>
            </div>
          )}
        </div>

        {/* Events */}
        <div className="flex flex-col gap-5 container mx-auto px-5 lg:px-20 py-10 ">
          <h2 className="text-2xl font-bold text-blueblack">Events</h2>
          {events.length > 0 ? (
            <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-5 lg:gap-10 ">
            {events.map(post => (
            <div key={post.id} className="flex flex-col gap-5 items-center max-w-sm bg-white lg:min-w-42 border border-gray-200 rounded-lg shadow">
              <img className="w-full rounded-t-lg object-cover h-52" src={post.file} alt={post.title} />
              <div className="p-3 lg:h-18">
                <Link to={`/events/${post.id}`} >
                  <h3 className="mb-2 text-l font-bold text-blueblack">{post.title}</h3>
                </Link>
                {/* <p className="mb-3 text-blueblack line-clamp-3 break-all">{post.body}</p> */}
                <div dangerouslySetInnerHTML={{ __html: post.body }} className="mb-3 text-blueblack line-clamp-3 break-all" />
                <div className='flex justify-between items-center'>
                  <Link to={`/events/${post.id}`} >
                    <button className="px-4 py-2 bg-blueblack text-white text-sm font-semibold rounded-full hover:bg-seablue transition-all duration-500 ease-in-out transform"
                    // onClick={() => handleSelectedNews(post)}
                    >
                      Read More</button>
                  </Link>
                  <p className="text-sm text-blueblack font-semibold">{post.date}</p>
                </div>
              </div>
            </div>
            ))}
          </div>
          ) : (
            <div className="text-center grid gap-10">
              <h3 className="text-2xl text-blueblack font-semibold">There are no current events available.</h3>
            </div>
          )}
        </div>
      </motion.div>
    </motion.div>
  )
}