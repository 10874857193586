import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Link, useLocation } from 'react-router-dom';
import EIT from '../assets/images/EITlogo.png';
import { motion } from 'framer-motion';

const Navbar = () => {
  const location = useLocation();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const mobileMenuRef = useRef(null);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const closeMobileMenu = useCallback(() => {
    setIsMobileMenuOpen(false);
  }, []);
  
  useEffect(() => {
    // Close mobile menu when clicked outside
    const handleClickOutside = (event) => {
      if (isMobileMenuOpen && mobileMenuRef.current && !mobileMenuRef.current.contains(event.target)) {
        closeMobileMenu();
      }
    };

    // Add event listener for clicks outside mobile menu when it is open
    if (isMobileMenuOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      // Remove event listener when the mobile menu is closed
      document.removeEventListener('mousedown', handleClickOutside);
    }

    // Clean up the event listener when the component is unmounted
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isMobileMenuOpen, closeMobileMenu, mobileMenuRef]);

    return (        
        <motion.nav 
          className="fixed flex items-center h-16 top-0 left-0 w-full p-5 lg:py-7 lg:px-20 border-gray-300 bg-white dark:bg-gray-800 dark:border-gray-700 shadow-md z-40" 
          initial={{ y: -100}}
          animate={{ y: 0 }}
          transition={{ delay: 0.2, duration: 1.2, type: 'tween'}}
        >
          <div className="container flex flex-wrap items-center justify-between mx-auto">
            <Link href="/" className="flex items-center">
                <img src={EIT} className="md:h-8 mr-3 h-6" alt="EIT Logo" />
                <span className="self-center text-xl font-bold lg:font-semibold whitespace-nowrap text-blueblack dark:text-white hidden xl:block">EIT Ghana Limited</span>
            </Link>
            <button 
                data-collapse-toggle="navbar-solid-bg" 
                type="button" 
                className="inline-block items-center p-2 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400" 
                aria-controls="navbar-solid-bg" 
                aria-expanded={isMobileMenuOpen}
                onClick={toggleMobileMenu}
            >
              {isMobileMenuOpen ? (
                // Close button icon
                <svg
                  className="w-6 h-6"
                  aria-hidden="true"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
                </svg>
              ) : (
                // Hamburger menu icon
                <svg
                  className="w-6 h-6"
                  aria-hidden="true"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7"></path>
                </svg>
              )}
            </button>

            <div 
              className={`${isMobileMenuOpen ? 'block' : 'hidden'} md:block pt-32 md:pt-0 w-full md:w-auto`}
              id="navbar-solid-bg"
              ref={mobileMenuRef}
            >
              <ul className="flex flex-col items-center text-white justify-between mt-10 rounded-lg bg-blueblack md:flex-row md:space-x-8 md:mt-0 md:text-sm xl:text-base md:border-0 md:bg-transparent dark:bg-gray-800 md:dark:bg-transparent dark:border-gray-700">
                <li>
                  <Link to="/" 
                    className={`block py-2 pl-3 pr-4 md:text-blueblack hover:bg-transparent md:border-0 hover:text-seablue md:p-0 ${location.pathname === '/' ? 'md:text-seablue md:underline' : ''}`}
                    onClick={closeMobileMenu}>Home</Link>
                </li>
                <li>
                  <Link to="/about" 
                    className={`block py-2 pl-3 pr-4 md:text-blueblack hover:bg-transparent md:border-0 hover:text-seablue md:p-0 ${location.pathname === '/about' ? 'md:text-seablue md:underline' : ''}`}
                    onClick={closeMobileMenu}>About</Link>
                </li>
                <li>
                  <Link to="/contact" 
                    className={`block py-2 pl-3 pr-4 md:text-blueblack hover:bg-transparent md:border-0 hover:text-seablue md:p-0 ${location.pathname === '/contact' ? 'md:text-seablue md:underline' : ''}`}
                    onClick={closeMobileMenu}>Contact</Link>
                </li>
                <li>
                  <Link to="/careers" 
                    className={`block py-2 pl-3 pr-4 md:text-blueblack hover:bg-transparent md:border-0 hover:text-seablue md:p-0 ${location.pathname === '/careers' ? 'md:text-seablue md:underline' : ''}`}
                    onClick={closeMobileMenu}>Careers</Link>
                </li>
                <li>
                  <Link to="/services" 
                    className={`block py-2 pl-3 pr-4 md:text-blueblack hover:bg-transparent md:border-0 hover:text-seablue md:p-0 ${location.pathname === '/services' ? 'md:text-seablue md:underline' : ''}`}
                    onClick={closeMobileMenu}>Services</Link>
                </li>
                <li>
                  <Link to="/news-and-events" 
                    className={`block py-2 pl-3 pr-4 md:text-blueblack hover:bg-transparent md:border-0 hover:text-seablue md:p-0 ${location.pathname === '/news-and-events' ? 'md:text-seablue md:underline' : ''}`}
                    onClick={closeMobileMenu}>News and Events</Link>
                </li>
              </ul>
            </div>
          </div>
        </motion.nav>

    );
}

export default Navbar;