import { Link, useNavigate } from 'react-router-dom';
import EIT from '../assets/images/EITlogo.png';
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { onAuthStateChanged, signOut } from "firebase/auth";
import { auth } from '../../src/context/authFirebase';

const Navbar = () => {
  const [currentUser, setCurrentUser] = useState(null);
  const navigate = useNavigate();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const mobileMenuRef = useRef(null);

  // Hamburguer menu
  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };


  const closeMobileMenu = useCallback(() => {
    setIsMobileMenuOpen(false);
  }, []);

  // // Close mobile menu when clicked outside
  // const handleClickOutside = (event) => {
  //   if (isMobileMenuOpen && mobileMenuRef.current && !mobileMenuRef.current.contains(event.target)) {
  //     closeMobileMenu();
  //   }
  // };

  // Check if user is logged in
  useEffect(() => {
    const listen = onAuthStateChanged(auth, (user) => {
      if (user) {
        setCurrentUser(user);
      } else {
        setCurrentUser(null);
      }
    });

    return () => listen();
  }, []);

  // Logout function
  const logout = () => {
    signOut(auth)
    .then(() => {
      navigate('/login')
    })
    .catch((err) => {
      console.log(err);
    });
  };

  useEffect(() => {
    // Close mobile menu when clicked outside
    const handleClickOutside = (event) => {
      if (isMobileMenuOpen && mobileMenuRef.current && !mobileMenuRef.current.contains(event.target)) {
        closeMobileMenu();
      }
    };

    // Add event listener for clicks outside mobile menu when it is open
    if (isMobileMenuOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      // Remove event listener when the mobile menu is closed
      document.removeEventListener('mousedown', handleClickOutside);
    }

    // Clean up the event listener when the component is unmounted
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isMobileMenuOpen, closeMobileMenu, mobileMenuRef]);

    return (        
        <nav className="fixed flex items-center lg:h-24 h-20 top-0 left-0 w-full p-5 lg:py-7 lg:px-20 border-gray-300 bg-white dark:bg-gray-800 dark:border-gray-700 shadow-md z-40">
          <div className="container flex flex-wrap items-center justify-between mx-auto">
            <a href="/" className="flex items-center">
                <img src={EIT} className="h-8 mr-3 sm:h-16" alt="EIT Logo" />
                <span className="self-center text-3xl font-bold lg:font-semibold whitespace-nowrap text-blueblack dark:text-white hidden xl:block">EIT Ghana Limited</span>
            </a>
            <button 
                data-collapse-toggle="navbar-solid-bg" 
                type="button" 
                className="inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" 
                aria-controls="navbar-solid-bg" 
                aria-expanded={isMobileMenuOpen}
                onClick={toggleMobileMenu}
            >
              <span className="sr-only">Open main menu</span>
              <svg 
                className="w-6 h-6" 
                aria-hidden="true" 
                fill="currentColor" 
                viewBox="0 0 20 20" 
                xmlns="http://www.w3.org/2000/svg">
                    <path 
                        fill-rule="evenodd" 
                        d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" 
                        clip-rule="evenodd">
                    </path>
                </svg>
            </button>
            <div 
              className={`${isMobileMenuOpen ? 'block' : 'hidden'} md:block pt-0 md:pt-0 w-full md:w-auto`} 
              id="navbar-solid-bg"
              ref={mobileMenuRef}
            >
              <ul className="flex flex-col items-center text-white justify-between mt-0 rounded-lg bg-blueblack md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium md:border-0 md:bg-transparent dark:bg-gray-800 md:dark:bg-transparent dark:border-gray-700">
            
                {currentUser ? (
                  <>
                  <span className="block py-2 pl-3 pr-4 md:text-blueblack  md:border-0 md:p-0 ">{currentUser.email}</span>
                  <button onClick={logout} className='block py-2 md:px-4 rounded-3xl pl-3 pr-4 bg-blueblack md:text-white md:hover:bg-seablue'>Logout</button>
                  </>
                ) : (
                  <Link to="/login"></Link>
                )}
              </ul>
            </div>
          </div>
        </nav>

    );
}

export default Navbar;
