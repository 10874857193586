import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { db } from '../context/authFirebase';
import { doc, getDoc, getDocs, collection, query, orderBy, where, limit } from 'firebase/firestore';

export default function Events() {
  const { id } = useParams();
  const [events, setEvents] = useState(null);
  const [otherEvents, setOtherEvents] = useState([]);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const postRef = doc(db, 'Post', id); // Reference the 'posts' collection and the specific post by ID
        const docSnap = await getDoc(postRef);

        if (docSnap.exists()) {
          setEvents(docSnap.data());
        } else {
          console.log('Post not found');
        }

        // Get other events
        const otherEventsQuery = query(
          collection(db, 'Post'),
          where('category', '==', 'events'),
          orderBy('timeStamp', 'desc'),
          limit(3)
        );
        const otherEventsSnapshot = await getDocs(otherEventsQuery);
        const otherEventsData = otherEventsSnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));

        // Filter out the current events
        const filteredEvents = otherEventsData.filter((post) => post.id !== id);
        setOtherEvents(filteredEvents);

      } catch (error) {
        console.error('Error fetching post:', error);
      }
    };

    fetchEvents();
  }, [id]);

  useEffect(() => {
    document.title = "EIT Ghana Limited - Events"
  }, []);

  if(!events) return <h1 className='text-3xl font-bold text-center text-blueblack mx-auto px-5 lg:px-20 py-10 lg:py-10'>Event post not found.</h1>;

  return (
    <div className='lg:flex relative top-16'>
      {/* Main events */}
      {events && (
        <div className='lg:w-3/4 flex flex-col gap-5 container mx-auto px-5 lg:pl-20 py-10 lg:py-10'>
          <div>
            <img src={events.file} className='w-full max-w-[400px] lg:h-72 object-cover mb-12' alt={events.title} />
            <div>
              <h3 className='text-3xl font-bold text-blueblack'>{events.title}</h3>
              {/* <p className='mt-4 text-blueblack'>{events.body}</p> */}
              <div className='mt-4 text-blueblack' dangerouslySetInnerHTML={{ __html: events.body }} />
            </div>
          </div>
        </div>
      )}

      {/* Other events */}
      <div className='lg:w-1/4 flex flex-col gap-5 container mx-auto px-5 lg:pr-20 py-10 lg:py-10'>
        <h3 className='text-xl font-bold text-blueblack'>Other events</h3>
        {otherEvents.map((post) => (
          <div key={post.id}>
            <img src={post.file} className='w-full hidden lg:block' alt={post.title} />
            <div className='px-2'>
              <Link to={`/events/${post.id}`} > 
                <h3 className="text-xl font-bold text-blueblack">{post.title}</h3>
              </Link>
              {/* <p className='mb-2 text-blueblack line-clamp-3 break-all'>{post.body}</p> */}
              <div dangerouslySetInnerHTML={{ __html: post.body }} className='mb-2 text-blueblack line-clamp-3 break-all' />
              <Link to={`/events/${post.id}`} >
                <button className="px-4 py-2 bg-blueblack text-white text-sm font-semibold rounded-full hover:bg-seablue transition-all duration-500 ease-in-out transform"
                // onClick={() => handleSelectData(post)}
                >
                  Read More
                </button>
              </Link>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}