import React, { useState, useEffect } from "react";
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Careers from "./pages/Careers";
import Services from "./pages/Services";
import NewsEvents from "./pages/NewsEvents";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import News from "./components/News";
import Events from "./components/Events";
import Login from "./pages/Login";
import AddPost from "./pages/AddPost";
import Career from "./components/Career";
import EditPost from "./pages/EditPost";
import Dashboard from "./pages/Dashboard";
import { createBrowserRouter, RouterProvider, Outlet, Link } from "react-router-dom";
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../src/context/authFirebase';
import { AnimatePresence } from 'framer-motion';

function App() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    // Check if the user is logged in or not
    onAuthStateChanged(auth, (user) => {
      setUser(user);
    });
  }, []);

  const Layout = () => {
    return (
      <div className="grid">
        <Navbar />
        <div className="container mx-auto">
          <AnimatePresence mode="wait">
            <Outlet />
          </AnimatePresence>
        </div>
        <Footer />
      </div>
    )
  };

  // Protected Route: Post page
  const ProtectedRoute = ({ element }) => {
    if (user) {
      return element;
    } else {
      return (
        <div className="grid place-items-center bg-blueblack text-white h-screen py-80 md:py-96 lg:py-56">
          <p className="md:text-3xl">Please log in to access this page.</p>
          <Link to="/login">
            <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full mt-4">
              Login
            </button>
          </Link>
        </div>
      );
    }
  };

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Layout />,
      children: [
        { path: "/", element: <Home /> },
        { path: "/about", element: <About />, pageName: "About" },
        { path: "/contact", element: <Contact />, pageName: "Contact" },
        { path: "/careers", element: <Careers />, pageName: "Careers" },
        { path: "/services", element: <Services />, pageName: "Services" },
        { path: "/news-and-events", element: <NewsEvents />, pageName: "News & Events" },
        { path: "/news/:id", element: <News />, pageName: "News" },
        { path: "/events/:id", element: <Events />, pageName: "Events" },
        { path: "/careers/:id", element: <Career />, pageName: "Career" },
      ],
    },
    { path: "/login", element: <Login /> },
    { path: "/add-post", element: <ProtectedRoute element={<AddPost />} /> },
    { path: "/edit-post/:id", element: <ProtectedRoute element={<EditPost />} /> },
    { path: "/dashboard", element: <ProtectedRoute element={<Dashboard />} /> },
  ]);

  return (
    <div className="app">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
