// import React from 'react';
import moment from 'moment/moment';
import { Link } from 'react-router-dom';
import EIT from '../assets/images/EITlogo.jpg';
import { BsFacebook, BsInstagram, BsLinkedin } from 'react-icons/bs';

const Footer = () => {
    return (
        <footer className="bottom-0 left-0 relative lg:top-16 top-8 w-full bg-seablue px-5 lg:px-20 py-10 md:items-center md:justify-between md:py-6">
            <div className="mx-auto w-full container">
                <div className="justify-between">
                  <div className="mb-10 md:mb-12">
                      <a href="/" className="flex items-center">
                          <img src={EIT} className="h-24 mr-3" alt="EIT Logo" />
                          {/* <span className="self-center text-2xl font-semibold whitespace-nowrap text-white">U-Care Foundation</span> */}
                      </a>
                  </div>
                  <div className="grid grid-cols-2 lg:justify-between gap-8 sm:gap-0 md:grid-cols-3">
                      <div>
                          <h2 className="mb-6 text-sm font-bold uppercase text-white">EIT Ghana Limited</h2>
                          <ul className="text-gray-100 dark:text-gray-400 text-sm font-normal">
                              <li className="mb-3">
                                <Link to="/about" className="hover:underline">About Us</Link>
                              </li>
                              <li className="mb-3">
                                <Link to="/contact" className="hover:underline">Contact Us</Link>
                              </li>
                          </ul>
                      </div>
                      <div>
                          <h2 className="mb-6 text-sm font-bold uppercase text-white">Company</h2>
                          <ul className="text-gray-100 dark:text-gray-400 text-sm font-normal">
                              <li className="mb-3">
                                <Link to="/careers" className="hover:underline">Careers</Link>
                              </li>
                              <li className="mb-3">
                                <Link to="/services" className="hover:underline">Services</Link>
                              </li>
                              <li>
                                <Link to="/news-and-events" className="hover:underline">News and Events</Link>
                              </li>
                          </ul>
                      </div>
                      <div>
                          <h2 className="mb-6 text-sm font-bold uppercase text-white">Follow us</h2>
                          <ul className="text-gray-100 dark:text-gray-400 text-sm font-normal">
                              <li className="mb-3">
                                  <a href="https://www.linkedin.com/company/eit-ghana-limited" target="_blank" rel="noreferrer" className="hover:underline ">LinkedIn</a>
                              </li>
                              <li className="mb-3">
                                  <a href="https://web.facebook.com/eitghanalimited" target="_blank" rel="noreferrer" className="hover:underline">Facebook</a>
                              </li>
                              <li>
                                  <a href="https://www.instagram.com/eitghanalimited" target="_blank" rel="noreferrer" className="hover:underline">Instagram</a>
                              </li>
                          </ul>
                      </div>
                  </div>
                </div>
                <hr className="my-6 border-gray-400 sm:mx-auto dark:border-gray-700 lg:my-8" />
                <div className="sm:flex sm:items-center sm:justify-between">
                  <span className="text-sm text-white sm:text-center dark:text-gray-400">© {moment().format('YYYY')}  Developed with 💖 by <a href="https://www.eitghana.com" target="_blank" rel="noreferrer">EIT Ghana Limited</a>
                  </span>
                  <div className="flex mt-4 space-x-6 sm:justify-center sm:mt-0">
                      <a href="https://www.linkedin.com/company/eit-ghana-limited" target="_blank" rel="noreferrer" className="text-white hover:text-amber-600 dark:hover:text-white">
                          <BsLinkedin />
                          <span className="sr-only">LinkedIn page</span>
                      </a>
                      <a href="https://web.facebook.com/eitghanalimited" target="_blank" rel="noreferrer" className="text-white hover:text-amber-600 dark:hover:text-white">
                          <BsFacebook />
                          <span className="sr-only">Facebook page</span>
                      </a>
                      <a href="https://www.instagram.com/eitghanalimited" target="_blank" rel="noreferrer" className="text-white hover:text-amber-600 dark:hover:text-white">
                          <BsInstagram />
                          <span className="sr-only">Instagram page</span>
                      </a>
                  </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;

