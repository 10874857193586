import React, { useEffect, useState} from 'react';
import Footer from '../components/Footer';
import UserNavbar from '../components/UserNavbar';
import { collection, doc, getDocs, deleteDoc, query, where, orderBy } from 'firebase/firestore';
import { db } from '../context/authFirebase';
import { Link } from 'react-router-dom';

export default function Dashboard() {
  const [posts, setPosts] = useState([]);

  // Fetch post
  const fetchPost = async () => {
    try {
      const q = query(
        collection(db, 'Post'),
        where(
          'category',
          'in',
          ['news', 'events', 'careers']
        ),
        orderBy('timeStamp', 'desc')
      );
      const data = await getDocs(q);
      setPosts(
        data.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }))
      );
    } catch (error) {
      console.error('Error fetching post:', error);
    }
  };

  useEffect(() => {
    fetchPost();
  }, []);

  // Delete post
  const handleDelete = async (id) => {
    try {
      await deleteDoc(doc(db, 'Post', id));
      fetchPost();
      setPosts(posts.filter((post) => post.id !== id));
    } catch (error) {
      console.error('Error deleting post:', error);
    }
  }

  return (
    <>
    <UserNavbar></UserNavbar>

      <div className="container mx-auto relative lg:top-24 top-20 px-5 lg:px-20 py-10 lg:py-10">
        <h2 className="text-2xl font-bold mb-4">EIT Ghana Limited Posts</h2>
        <Link className="bg-blue-800 hover:bg-blue-600 px-4 py-2 text-white font-bold rounded" to="/add-post">Add New Post</Link>

        <table className="mt-4 w-full border-collapse">
          <thead>
            <tr className="bg-gray-400">
              <th className="border px-2 py-2">Title</th>
              <th className='border px-2 py-2 hidden md:table-cell'>CreatedAt</th>
              <th className="border px-2 py-2">Category</th>
              <th className="border px-2 py-2">Actions</th>
            </tr>
          </thead>
          <tbody>
            {posts.map((post, index) => (
              <tr
                key={post.id}
                className={index % 2 === 0 ? 'bg-gray-400' : 'bg-gray-300'}
              >
                <td className="border px-2 py-2">{post.title}</td>
                <td className='border px-2 py-2 hidden md:table-cell'>{post.date}</td>
                <td className="border px-2 py-2 text-center">{post.category}</td>
                <td className="border px-2 py-2 lg:flex lg:justify-between">
                  <button
                    className="font-semibold text-red-600"
                    onClick={() => handleDelete(post.id)}
                  >
                    Delete
                  </button>
                  <Link to={`/edit-post/${post.id}`}>
                    <button className="font-semibold text-blue-600">Edit</button>
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

    <Footer></Footer>
    </>
  )
}