import React, {useEffect} from 'react';
import entrepreneurship from '../assets/images/entrepreneurship.jpg';
import innovation from '../assets/images/innovation.jpg';
import technology from '../assets/images/technology.jpg';
import marketing from '../assets/images/marketing.jpg';
import { motion } from 'framer-motion';
import { fadeIn } from '../assets/variants';

export default function Services() {
  const containerVariants = {
    hidden : {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: {
        duration: 1,
      }
    },
    exit: {
      transition: {ease: 'easeInOut'}
    }
  }

  useEffect(() => {
    document.title = "EIT Ghana Limited - Our Services"
  }, []);

  return (
    <motion.div 
      className="flex flex-col bg-gray-200 relative top-16"
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      exit= "exit"
    >
      {/* First Part */}
      <div className="h-[50vh] bg-blueblack flex flex-col gap-10 place-content-center px-5 lg:px-20 py-10 lg:py-10">
        <div className="flex flex-col items-center justify-center gap-8">
          <div className="w-full lg:w-4/5">
            <h1 className="text-3xl font-bold text-center text-gray-300">Our Services</h1>
            <p className="mt-4 text-center text-gray-100">
              EIT Ghana is a hub that empowers entrepreneurs through training, venture capital services, 
              and digital marketing solutions. We provide the knowledge, resources, and connections necessary 
              for business success. EIT Ghana supports entrepreneurs in unlocking their potential 
              and making a positive impact in Ghana's entrepreneurial landscape.
            </p>
          </div>
        </div>
      </div>

      {/* Second Part */}
      <motion.div 
        className="py-5 lg:py-10 grid gap-5 lg:gap-10 px-5 lg:px-20"
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ delay: 1, duration: 1}}
        viewport={{ once: true }}
      >
        {/* IT Servives */}
        <motion.div 
          id="its" 
          className="flex flex-col-reverse lg:flex lg:flex-row lg:gap-10 items-center container mx-auto rounded-md bg-white lg:bg-transparent"
          variants={fadeIn('up', 0.2)}
          initial='hidden'
          whileInView='show'
          viewport={{once: false }}
        >
          <motion.img 
            src={technology} 
            alt="" 
            className="object-cover w-full lg:w-2/5 h-full rounded-md " 
            variants={fadeIn('right', 0.2)}
            initial='hidden'
            whileInView='show'
            viewport={{once: false}}
          />
          <div 
            className="flex flex-col gap-10 container mx-auto px-5 lg:px-20 py-10 lg:py-10 bg-white rounded-t-md lg:rounded-md h-full "
            variants={fadeIn('left', 0.2)}
            initial='hidden'
            whileInView='show'
            viewport={{once: false}}
          >
            <h1 className="text-3xl font-bold text-center text-blueblack">Commercial IT Services</h1>
            <p className="text-justify">
              With our IT services, we specialize in software development, crafting top-notch mobile apps, web apps, 
              and desktop applications. Additionally, we excel in building cutting-edge websites to help businesses 
              thrive in the digital realm. Trust us to bring your innovative ideas to life and deliver exceptional solutions 
              tailored to your needs.
            </p>
          </div>
        </motion.div>
        {/* Vocational & Entrepreneurship Training */}
        <motion.div 
          id="vets" 
          className="flex flex-col lg:flex lg:flex-row lg:gap-10 items-center container mx-auto bg-white rounded-md lg:bg-transparent"
          variants={fadeIn('up', 0.2)}
          initial='hidden'
          whileInView='show'
          viewport={{once: false }}
        >
          <motion.div 
            className="flex flex-col gap-10 container mx-auto px-5 lg:px-20 py-10 lg:py-10 bg-white rounded-t-md lg:rounded-md h-full "
            variants={fadeIn('right', 0.2)}
            initial='hidden'
            whileInView='show'
            viewport={{once: false}}
          >
            <h1 className="text-3xl font-bold text-center text-blueblack">Vocational & Entrepreneurship Training</h1>
            <p className="text-justify">
              Our entrepreneurship training programs are carefully designed to empower you with the tools and 
              insights necessary to turn your innovative ideas into successful ventures. Whether you're a first-time 
              entrepreneur or a seasoned business professional, our expert trainers will guide you through a dynamic 
              curriculum that covers essential topics such as business planning, market analysis, financial 
              management, and strategic decision-making.
            </p>
          </motion.div>
          <motion.img  
            src={entrepreneurship} 
            alt="" 
            className="object-cover w-full lg:w-2/5 h-full rounded-md " 
            variants={fadeIn('left', 0.2)}
            initial='hidden'
            whileInView='show'
            viewport={{once: false}}
          />
        </motion.div>
        {/* Venture Capital Services */}
        <motion.div 
          id="vcs" 
          className="flex flex-col-reverse lg:flex lg:flex-row lg:gap-10 items-center container mx-auto rounded-md bg-white lg:bg-transparent"
          variants={fadeIn('up', 0.2)}
          initial='hidden'
          whileInView='show'
          viewport={{once: false }}
        >
          <motion.img 
            src={innovation} 
            alt="" 
            className="object-cover w-full lg:w-2/5 h-full rounded-md " 
            variants={fadeIn('right', 0.2)}
            initial='hidden'
            whileInView='show'
            viewport={{once: false}}
          />
          <motion.div 
            className="flex flex-col gap-10 container mx-auto px-5 lg:px-20 py-10 lg:py-10 bg-white rounded-t-md lg:rounded-md h-full "
            variants={fadeIn('left', 0.2)}
            initial='hidden'
            whileInView='show'
            viewport={{once: false}}
          >
            <h1 className="text-3xl font-bold text-center text-blueblack">Venture Capital Services</h1>
            <p className="text-justify">
              At EIT Ghana, we understand that securing funding is often the pivotal point for startups and small businesses 
              to thrive. That's why we have built a strong network of venture capitalists and angel investors who are 
              passionate about supporting innovative ideas and promising ventures.
            </p>
          </motion.div>
        </motion.div>
        {/* Marketing Services */}
        <motion.div 
          id="mkts" 
          className="flex flex-col lg:flex lg:flex-row mb-10 lg:mb-0 lg:gap-10 items-center container mx-auto rounded-md bg-white lg:bg-transparent"
          variants={fadeIn('up', 0.2)}
          initial='hidden'
          whileInView='show'
          viewport={{once: false }}
        >
          <motion.div 
            className="flex flex-col gap-10 container mx-auto px-5 lg:px-20 py-10 lg:py-10 bg-white rounded-t-md lg:rounded-md h-full "
            variants={fadeIn('right', 0.2)}
            initial='hidden'
            whileInView='show'
            viewport={{once: false}}
          >
            <h1 className="text-3xl font-bold text-center text-blueblack">Marketing Services</h1>
            <p className="text-justify">
              At EIT Ghana, we understand the power of effective marketing in driving business success. Our expert team of 
              digital marketing strategists, creative designers, and data analysts are dedicated to crafting tailored 
              solutions that align with your unique goals and target audience.
            </p>
          </motion.div>
          <motion.img 
            src={marketing} 
            alt="" 
            className="object-cover w-full lg:w-2/5 h-full rounded-md " 
            variants={fadeIn('left', 0.2)}
            initial='hidden'
            whileInView='show'
            viewport={{once: false}}
          />
        </motion.div>
      </motion.div>
    </motion.div>
  )
}